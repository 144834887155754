import request from '@/utils/request'

export function addDynamic(data) {
  return request({
    url: '/crm/tDynamicInfo',
    method: 'post',
    data
  })
}


export function editDynamic(data) {
  return request({
    url: '/crm/tDynamicInfo',
    method: 'PUT',
    data
  })
}
