<template>
  <el-dialog
    :append-to-body="true"
    v-if="dialogShow"
    :visible="dialogShow"
    :before-close="beforeCloseHandle"
    :title="title"
    width="800px"
    class="pay-add-or-edit-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="122px"
    >
      <el-form-item
          label="类型"
          prop="type"
      >
        <el-radio
            @change="typeChange"
            v-model="form.type"
            :disabled="typeDisabled"
            :label="1"
        >Banner</el-radio>
        <el-radio
            @change="typeChange"
            v-model="form.type"
            :disabled="typeDisabled"
            :label="2"
        >动态</el-radio>
      </el-form-item>
      <el-form-item
          v-if="isBanner"
          prop="bannerSubject" label="主题">
        <el-input
            v-model="form.bannerSubject"
            placeholder="请输入"
            show-word-limit
            maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item
          v-if="isBanner"
          prop="bannerLink"
          label="跳转链接"
      >
        <el-input
            v-model="form.bannerLink"
            placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
          v-if="isBanner"
          prop="bannerShowLang"
          label="显示语言"
      >
        <el-radio
            v-model="form.bannerShowLang"
            :label="0"
        >全部</el-radio>
        <el-radio
            v-model="form.bannerShowLang"
            :label="1"
        >中文</el-radio>
        <el-radio
            v-model="form.bannerShowLang"
            :label="2"
        >英文</el-radio>
      </el-form-item>
      <el-form-item
          v-if="isBanner && isZh"
          class="two-form-item"
      >
<!--        :label="`<span class='star'>*</>span>中文图片`"-->
        <p class="two-item-p">
          <span class='star'>*</span>中文图片
        </p>
        <el-row :gutter="10">
          <el-col :span="10">
            <el-form-item
              prop="bannerPcZhImage"
            >
              <div class="upload-box pr">
                <el-upload
                    class="avatar-uploader"
                    action="''"
                    :accept="getLimitUploadImageFormat()"
                    :show-file-list="false"
                    :http-request="uploadBannerPcZhImage"
                    :before-upload="beforeAvatarUpload">
<!--                  :on-success="handleBannerPcZhImageSuccess"-->
                  <img
                      v-if="form.bannerPcZhImage"
                      :src="form.bannerPcZhImage"
                      class="avatar middle"
                  >
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <uploadImgTips class="upload-tips"></uploadImgTips>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
                prop="bannerMobileZhImage"
            >
              <div class="upload-box pr">
                <el-upload
                    class="avatar-uploader"
                    action="''"
                    :accept="getLimitUploadImageFormat()"
                    :show-file-list="false"
                    :http-request="uploadBannerMoblieZhImage"
                    :before-upload="beforeAvatarUpload">
<!--                  :on-success="handleBannerMoblieZhImageSuccess"-->
                  <img
                      v-if="form.bannerMobileZhImage"
                      :src="form.bannerMobileZhImage"
                      class="avatar middle"
                  >
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <uploadImgTips class="upload-tips"></uploadImgTips>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="10">
            <p class="upload-img-tip text-center">PC端</p>
          </el-col>
          <el-col :span="10">
            <p class="upload-img-tip text-center">移动端</p>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
          v-if="isBanner && isEnglish"
          class="two-form-item"
      >
        <p class="two-item-p">
          <span class='star'>*</span>英文图片
        </p>
        <el-row :gutter="10">
          <el-col :span="10">
            <el-form-item
              prop="bannerPcEnImage"
            >
              <div class="upload-box pr">
                <el-upload
                    class="avatar-uploader"
                    action="''"
                    :accept="getLimitUploadImageFormat()"
                    :show-file-list="false"
                    :http-request="uploadBannerPcEnImage"
                    :before-upload="beforeAvatarUpload">
<!--                  :on-success="handleBannerPcEnImageSuccess"-->
                  <img
                      v-if="form.bannerPcEnImage"
                      :src="form.bannerPcEnImage"
                      class="avatar middle"
                  >
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <uploadImgTips class="upload-tips"></uploadImgTips>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
                prop="bannerMobileEnImage"
            >
              <div class="upload-box pr">
                <el-upload
                    class="avatar-uploader"
                    action="''"
                    :accept="getLimitUploadImageFormat()"
                    :show-file-list="false"
                    :http-request="uploadBannerMoblieEnImage"
                    :before-upload="beforeAvatarUpload">
<!--                  :on-success="handleBannerMoblieEnImageSuccess"-->
                  <img
                      v-if="form.bannerMobileEnImage"
                      :src="form.bannerMobileEnImage"
                      class="avatar middle"
                  >
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <uploadImgTips class="upload-tips"></uploadImgTips>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="10">
            <p class="upload-img-tip text-center">PC端</p>
          </el-col>
          <el-col :span="10">
            <p class="upload-img-tip text-center">移动端</p>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        v-if="isBanner"
        label="状态"
        prop="bannerStatus"
        hide-required-asterisk
      >
        <el-radio
            v-model="form.bannerStatus"
            :label="1"
        >显示</el-radio>
        <el-radio
            v-model="form.bannerStatus"
            :label="0"
        >不显示</el-radio>
      </el-form-item>
      <!--banner部分结束-->

      <el-form-item
          v-if="isDynamic"
        label="中文标题"
        prop="dynamicTitleZh"
          key="dynamicTitleZh"
      >
        <el-input
          v-model="form.dynamicTitleZh"
          placeholder="请输入"
          show-word-limit
          maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item
          v-if="isDynamic"
          label="英文标题"
          prop="dynamicTitleEn"
          key="dynamicTitleEn"
      >
        <el-input
            v-model="form.dynamicTitleEn"
            placeholder="请输入"
            show-word-limit
            maxlength="60"
        ></el-input>
      </el-form-item>
      <el-form-item
          v-if="isDynamic"
          prop="dynamicLink"
          label="跳转链接"
      >
        <el-input
            v-model="form.dynamicLink"
            placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
          v-if="isDynamic"
          class="two-form-item"
      >
        <p class="two-item-p">
          <span class='star'>*</span>封面
        </p>
        <el-row :gutter="10">
          <el-col :span="10">
            <el-form-item
                prop="dynamicCoverZh"
            >
              <div class="upload-box pr">
                <el-upload
                    class="avatar-uploader"
                    action="''"
                    :accept="getLimitUploadImageFormat()"
                    :show-file-list="false"
                    :http-request="uploadDynamicCoverZh"
                    :before-upload="beforeAvatarUpload">
<!--                  :on-success="handleDynamicCoverZhSuccess"-->
                  <img
                      v-if="form.dynamicCoverZh"
                      :src="form.dynamicCoverZh"
                      class="avatar middle"
                  >
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <uploadImgTips class="upload-tips"></uploadImgTips>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
                prop="dynamicCoverEn"
            >
              <div class="upload-box pr">
                <el-upload
                    class="avatar-uploader"
                    action="''"
                    :accept="getLimitUploadImageFormat()"
                    :show-file-list="false"
                    :http-request="uploadDynamicCoverEn"
                    :before-upload="beforeAvatarUpload">
<!--                  :on-success="handleDynamicCoverEnSuccess"-->
                  <img
                      v-if="form.dynamicCoverEn"
                      :src="form.dynamicCoverEn"
                      class="avatar middle"
                  >
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <uploadImgTips class="upload-tips"></uploadImgTips>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="10">
            <p class="upload-img-tip text-center">中文版</p>
          </el-col>
          <el-col :span="10">
            <p class="upload-img-tip text-center">英文版</p>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        v-if="isDynamic"
        label="状态"
        prop="dynamicStatus"
        hide-required-asterisk
      >
        <el-radio
            v-model="form.dynamicStatus"
            :label="1"
        >显示</el-radio>
        <el-radio
            v-model="form.dynamicStatus"
            :label="0"
        >不显示</el-radio>
      </el-form-item>
      <!--动态-->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click.stop="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { uploadFile } from '@/api/data'
import { getLimitUploadImageFormat,limitImageSizeFormat,getBase64, base64toFile } from '@/utils/index'
import { mapGetters } from 'vuex'

import { addDynamic,editDynamic } from '@/api/notificationManager/dynamic'
let assginForm = {
  type: 1,
  // banner
  bannerSubject: '', //主题
  bannerLink: '', // 跳转链接
  bannerShowLang: 0,
  bannerPcZhImage: '', // 中文图片 pc端
  bannerMobileZhImage: '', // 中文图片 mobile端
  bannerPcEnImage: '', // 英文图片 pc端
  bannerMobileEnImage: '', // 英文图片 移动端
  bannerStatus: 1, //Banner状态
  // banner
  //动态
  dynamicTitleZh: '', //动态中文标题
  dynamicTitleEn: '', //动态英文标题
  dynamicLink: '', //动态英文标题
  dynamicCoverZh: '', //封面中文版
  dynamicCoverEn: '', //封面英文版
  dynamicStatus: 1, //状态
  //动态
}
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
    dialogShow:{
      type: Boolean,
      default: false
    },
    rowInfo:{
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        id: '',
        ...assginForm,
      },
      rules:{
        bannerSubject:[
          { required: true, message: '请输入Banner主题', trigger: 'blur' },
        ],
        bannerLink:[
          // { required: true, message: '请输入Banner跳转链接', trigger: 'blur' },
        ],
        bannerPcZhImage:[
          { required: true, message: '请上传PC端中文图片', trigger: 'change' },
        ],
        bannerMobileZhImage:[
          { required: true, message: '请上传移动端中文图片', trigger: 'change' },
        ],
        bannerPcEnImage:[
          { required: true, message: '请上传PC端英文图片', trigger: 'change' },
        ],
        bannerMobileEnImage:[
          { required: true, message: '请上传移动端英文图片', trigger: 'change' },
        ],
        bannerStatus:[
          // { required: true, message: '选择Banner状态', trigger: 'change' },
        ],
        // 动态
        dynamicTitleZh:[
          { required: true, message: '请输入中文标题', trigger: 'blur' },
        ],
        dynamicTitleEn:[
          { required: true, message: '请输入英文标题', trigger: 'blur' },
        ],
        dynamicLink:[
          // { required: true, message: '请输入跳转链接', trigger: 'blur' },
        ],
        dynamicCoverZh:[
          { required: true, message: '请上传封面中文版图片', trigger: 'change' },
        ],
        dynamicCoverEn:[
          { required: true, message: '请上传封面英文版图片', trigger: 'change' },
        ],
        // 动态
      },
    }
  },
  watch:{
    rowInfo:{
      handler(newValue){
        if(Object.keys(newValue).length > 0 && this.isAdd == false){
          this.assignmentFormCom(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
    dialogShow(newValue){
      this.$nextTick(() => {
        if(newValue && this.isAdd){
          this.clearForm();
          this.$refs.form && this.$refs.form.clearValidate();
        }
        if(newValue && !this.isAdd){
          this.assignmentFormCom(this.rowInfo);
          this.$refs.form && this.$refs.form.clearValidate();
        }
      });
    },
  },
  computed: {
    ...mapGetters([]),
    title(){
      return this.isAdd ? '新增': '编辑';
    },
    isBanner(){
      return this.form.type == 1;
    },
    isDynamic(){
      return this.form.type == 2;
    },
    typeDisabled(){
      return !this.isAdd ? true : false;
    },
    isEnglish(){
      return this.form.bannerShowLang == 0 || this.form.bannerShowLang == 2;
    },
    isZh(){
      return this.form.bannerShowLang == 0 || this.form.bannerShowLang == 1;
    },
  },
  methods: {
    getLimitUploadImageFormat,
    clearForm(){
      this.form = {
        id: '',
        ...assginForm
      };
    },
    cancel() {
      this.close();
    },
    close(){
      this.$emit('close-dialog');
    },
    beforeCloseHandle(){
      this.close();
    },
    assignmentFormCom(data){
      this.form.id = data.id;
      this.form.type = data.type;
      if(data.type == 1){
        this.form.bannerSubject = data.title;
        this.form.bannerLink = data.redirectUrl;
        this.form.bannerShowLang = data.availableLang;
        this.form.bannerStatus = data.status;

        if(data.availableLang == 1){
          this.form.bannerPcZhImage = data.imgPc;
          this.form.bannerMobileZhImage = data.imgMobile;
        }else if(data.availableLang == 2){
          this.form.bannerPcEnImage = data.imgPcEn;
          this.form.bannerMobileEnImage = data.imgMobileEn;
        }else if(data.availableLang === 0){
          this.form.bannerPcZhImage = data.imgPc;
          this.form.bannerMobileZhImage = data.imgMobile;
          this.form.bannerPcEnImage = data.imgPcEn;
          this.form.bannerMobileEnImage = data.imgMobileEn;

        }
      }else if(data.type =2){
        this.form.dynamicTitleZh = data.title;
        this.form.dynamicTitleEn = data.titleEn;
        this.form.dynamicLink = data.redirectUrl;
        this.form.dynamicCoverZh = data.imgPc;
        this.form.dynamicCoverEn = data.imgPcEn;
        this.form.dynamicStatus = data.status;
      }
    },
    uploadBannerPcZhImage(item){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.bannerPcZhImage = res.data.content[0].url;
        });
      });
    },
    uploadBannerMoblieZhImage(item){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.bannerMobileZhImage = res.data.content[0].url;
        });
      });
    },
    uploadBannerPcEnImage(item){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.bannerPcEnImage = res.data.content[0].url;
        });
      });
    },
    uploadBannerMoblieEnImage(item){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.bannerMobileEnImage = res.data.content[0].url;
        });
      });
    },
    uploadDynamicCoverZh(item){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.dynamicCoverZh = res.data.content[0].url;
        });
      });
    },
    uploadDynamicCoverEn(item){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.dynamicCoverEn = res.data.content[0].url;
        });
      });
    },
    beforeAvatarUpload(file) {
      return limitImageSizeFormat(file,this);
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }else {
          console.log('error submit!!');
          return false;
        }
      })

    },
    getParams(params){
      if(this.form.type == 1){
        // banner类型
        params.title = this.form.bannerSubject;
        params.redirectUrl = this.form.bannerLink;
        // 语言漏了
        params.availableLang = this.form.bannerShowLang;
        if(this.form.bannerShowLang == 1){
          params.imgPc = this.form.bannerPcZhImage;
          params.imgMobile = this.form.bannerMobileZhImage;
        }else if(this.form.bannerShowLang == 2){
          params.imgPcEn = this.form.bannerPcEnImage;
          params.imgMobileEn = this.form.bannerMobileEnImage;

        }else if(this.form.bannerShowLang === 0){

          params.imgPc = this.form.bannerPcZhImage;
          params.imgMobile = this.form.bannerMobileZhImage;
          params.imgPcEn = this.form.bannerPcEnImage;
          params.imgMobileEn = this.form.bannerMobileEnImage;
        }
        params.status = this.form.bannerStatus;
      }else if(this.form.type == 2){
        params.title = this.form.dynamicTitleZh; //中文标题
        params.titleEn = this.form.dynamicTitleEn; // 英文标题
        params.redirectUrl = this.form.dynamicLink;
        params.imgPc = this.form.dynamicCoverZh;
        params.imgPcEn = this.form.dynamicCoverEn;
        params.status = this.form.dynamicStatus;
      }
    },
    doAdd() {
      let params = {
        type: this.form.type,
      };
      this.getParams(params);
      this.loading = true;
      addDynamic(params).then(res => {
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false;
        this.close();
        this.resetForm();
        this.$parent.init();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      let params = {
        id: this.form.id,
        type: this.form.type,
      }
      this.getParams(params);
      this.loading = true;
      editDynamic(params).then(res => {
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false;
        this.close();
        this.resetForm();
        this.$parent.init();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.$refs['form'] && this.$refs['form'].clearValidate();
      this.$refs['form'] && this.$refs['form'].resetFields();
    },
    typeChange(){
      this.$refs.form.clearValidate();
    },
  }
}
</script>
<style lang="less">
  .pay-add-or-edit-dialog {
    .el-dialog__body {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
</style>
<style lang="less" scoped>
.pay-add-or-edit-dialog {

  .upload-box {
    .avatar-uploader {
      width: 100%;
      height: 100px;
      border: 1px solid #c0c0c0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /deep/ .el-upload {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .avatar {
        width: 100%;
        height: 100%;
      }
    }

  }
  .upload-img-tip {
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 20px;
  }
  .el-form-item__label {
    word-break: break-all;
  }
  .two-form-item {
    position: relative;
    .two-item-p {
      position: absolute;
      left: -70px;
      top: 0;
      font-weight: 700;
    }
  }

  .star {
    color: red;
  }
}
</style>
